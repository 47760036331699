import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { Link as RouterLink } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Icon from '@material-ui/core/Icon';
import PlaceIcon from '@material-ui/icons/Place';
import PersonIcon from '@material-ui/icons/Person';
import VideocamIcon from '@material-ui/icons/Videocam';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 380,
  },
  media: {
    height: 140,
  },
  relatedList: {
    display: 'flex',
    alignItems: 'center'
  },
  relatedItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)
  }
}));


export default function ObjectCard(props) {
  const classes = useStyles();
  const {
    title,
    subtitle,
    link,
    image,
    relatedPlaceCount,
    relatedBookCount,
    relatedInvestigationCount,
    relatedTourCompanyCount,
    relatedLoreCount,
    relatedPersonCount
  } = props;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.root}>
        <CardActionArea component={RouterLink} to={link} >
          <CardMedia
            className={classes.media}
            image={`https://media.graphcms.com/resize=w:380,h:140,fit:crop,align:faces/monochrome/torn_edges=spread:[1,10]/${image.handle}`}
            // image={`https://media.graphcms.com/resize=w:345,h:140,fit:crop/sepia=tone:80/torn_edges=spread:[1,10]/${image.handle}`}
            // image={`https://media.graphcms.com/resize=w:345,h:140,fit:crop/monochrome/${image.handle}`}
            title={image.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>

            {subtitle &&
              <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                {subtitle}
              </Typography>
            }
            <Typography className={classes.relatedList} variant="body2" color="textSecondary">
              {relatedPlaceCount > 0 && <span className={classes.relatedItem}><PlaceIcon fontSize="small" /> {relatedPlaceCount}</span>}
              {relatedLoreCount > 0 && <span className={classes.relatedItem}><FontAwesomeIcon icon="scroll" style={{ fontSize: '1em', width: '1.5em' }}/> {relatedLoreCount}</span>}
              {relatedPersonCount > 0 && <span className={classes.relatedItem}><PersonIcon fontSize="small" /> {relatedPersonCount}</span>}
              {relatedInvestigationCount > 0 && <span className={classes.relatedItem}><VideocamIcon fontSize="small" /> {relatedInvestigationCount}</span>}
              {relatedBookCount > 0 && <span className={classes.relatedItem}><MenuBookIcon fontSize="small" /> {relatedBookCount}</span>}
              {relatedTourCompanyCount > 0 && <span className={classes.relatedItem}><FontAwesomeIcon icon="ghost" style={{ fontSize: '1em' }} /> {relatedTourCompanyCount}</span>}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}