import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    ogDefaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    imageWidth,
    imageHeight,
    twitterUsername,
    facebookAppId,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    ogTitle: title || ogDefaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      {/* general */}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" href={seo.url} />

      {/* facebook */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.ogTitle && <meta property="og:title" content={seo.ogTitle} />}
      {seo.description && (<meta property="og:description" content={seo.description} />)}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.image && <meta property="og:image:width" content={imageWidth} />}
      {seo.image && <meta property="og:image:height" content={imageHeight} />}
      {facebookAppId && (<meta property="fb:app_id" content={facebookAppId} />)}
      <meta property="fb:pages" content="113350117029953" />


      {/* twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (<meta name="twitter:creator" content={twitterUsername} />)}
      {seo.ogTitle && <meta name="twitter:title" content={seo.ogTitle} />}
      {seo.description && (<meta name="twitter:description" content={seo.description} />)}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO

export function SEODescription (fullDescription) {
  // Go until we find the first period, question mark, or exclemation mark
  const firstSentenceRegex = /^.*?[.?!]/;

  const matches = fullDescription.match(firstSentenceRegex);
  return matches ? matches[0] : '';
}

export function SEOImage (imageHandle) {
  const image = `https://media.graphcms.com/resize=w:1200,h:630,fit:crop,align:faces/monochrome/${imageHandle}`;
  return image;
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        ogDefaultTitle: ogTitle
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        imageWidth
        imageHeight
        twitterUsername
        facebookAppId
      }
    }
  }
`

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}